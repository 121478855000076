<template>
  <div>
    <DataTable
      :value="items"
      :loading="loading"
      class="p-datatable-sm"
      dataKey="kb_no"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      row-hover
      scrollable
      showGridlines
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column field="kb_no" header="No Kontra" style="min-width: 9rem" />
      <Column field="pi_no" header="No Invoice" style="min-width: 9rem" />
      <Column field="pr_no" header="No Retur" style="min-width: 9rem" />
      <Column
        dataType="date"
        field="kb_at"
        header="Tgl Bukti"
        style="min-width: 4rem"
      >
        <template #body="{ data }">
          <div v-if="data.kb_no">
            {{ formatDate(data.kb_at) }}
          </div>
          <div v-else-if="data.pi_no">
            {{ formatDate(data.pi_at) }}
          </div>
          <div v-else-if="data.pr_no">
            {{ formatDate(data.pr_at) }}
          </div>
        </template>
      </Column>
      <!--<Column
        field="diskon"
        header="Diskon"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.diskon) }}
        </template>
      </Column>-->
      <Column
        field="jumlah_dibayar"
        header="Jumlah lunas"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah_dibayar) }}
        </template>
      </Column>
      <Column field="keterangan" header="Keterangan" style="min-width: 150px" />
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatCurrency(value) {
      return formatCurrency(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
