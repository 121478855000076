import http from '../clients/Axios'

export default class KontraPembelianService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/purchase/bon/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    const res = await http.get(`/purchase/bon${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/purchase/bon', form)
    return res
  }

  async update(form) {
    const res = await http.post(`/purchase/bon/${form.id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/purchase/bon/${id}`)
    return res
  }

  async export(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    return http({
      url: `/purchase/bon/export?sort=${sort}${filters_param}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async downloadTelat(id) {
    return http({
      url: `/purchase/bon/${id}/print_stkb`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async download(id) {
    return http({
      url: `/purchase/bon/${id}/print`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async getAlasan(param) {
    param = param ? param : ''
    return await http.get(`/purchase/bon/alasan${param}`)
  }

  async addAlasan(form) {
    return await http.post('/purchase/bon/alasan', form)
  }
}
