import http from '../clients/Axios'

export default class HutangService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        let val = filters[el].value
        if (el === 'giro_status') {
          if (val === true) {
            val = "cair";
          } else {
            val = "";
          }
        }
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + val
        }
      })

    }

    const res = await http.get(
      `/purchase/payment/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    const res = await http.get(`/purchase/payment${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/purchase/payment', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/purchase/payment/${id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/purchase/payment/${id}`)
    return res
  }

  async export(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    return http({
      url: `/purchase/payment/export?sort=${sort}${filters_param}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async download(id) {
    return http({
      url: `/purchase/payment/${id}/print`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
