<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSaveHeader"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
          />
          <Button
            :disabled="old_status !== 'draft'"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Obat"
            @click="add"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'PO' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label>Tanggal</label>
                <Calendar
                  v-model="form.po_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>No PO</label>
                <InputText
                  v-model="form.po_no"
                  class="w-full"
                  disabled
                  autofocus
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Status</label>
                <Dropdown
                  v-model="form.status"
                  :options="list_status"
                  :disabled="form.id === 0 || old_status === 'rcvd'"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label
                  :class="{
                    'p-error': v$.form.golongan.$invalid && submitted,
                  }"
                >
                  Golongan
                </label>
                <Dropdown
                  v-model="form.golongan"
                  :options="list_golongan_obat"
                  :class="{
                    'p-invalid': v$.form.golongan.$invalid && submitted,
                  }"
                  :disabled="old_status !== 'draft'"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.golongan.$invalid && submitted) ||
                    v$.form.golongan.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.golongan.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-6">
                <label
                  :class="{
                    'p-error': v$.form.supplier.$invalid && submitted,
                  }"
                >
                  Supplier
                </label>
                <AutoComplete
                  v-model="form.supplier"
                  :suggestions="acSupplier"
                  :min-length="2"
                  :class="{
                    'p-invalid': v$.form.supplier.$invalid && submitted,
                  }"
                  class="w-full"
                  input-class="w-full"
                  placeholder="Supplier"
                  field="nama"
                  @complete="searchSupplier($event)"
                >
                  <template #item="slotProps">
                    <div>
                      {{ slotProps.item.kode }} - {{ slotProps.item.nama }}
                    </div>
                  </template>
                </AutoComplete>
                <small
                  v-if="
                    (v$.form.supplier.$invalid && submitted) ||
                    v$.form.supplier.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.supplier.required.$message }}</small
                >
              </div>
              <div class="col-12 md:col-2 field-checkbox md:mt-4">
                <Checkbox
                  id="status_bonus"
                  v-model="form.status_bonus"
                  :binary="true"
                  class="mr-1"
                />
                <label for="status_bonus">BONUS</label>
              </div>
            </div>
          </div>
        </div>
        <grid-po-detail
          :items="form.obat"
          :loading="isLoading"
          :disable-button="old_status === 'rcvd'"
          @edit="onEditBarang"
          @delete="onConfirmDeletion"
        />
      </div>
    </form>
    <Dialog
      :header="productIndex >= 0 ? 'Edit item' : 'Tambah item'"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      closeable
    >
      <form-obat
        :loading="isLoadingSaveBarang"
        :item="product"
        :satuan="list_satuan"
        :golongan="golongan"
        @close="onCloseFormBarang"
        @save="onSaveBarang"
      />
    </Dialog>
    <Dialog
      header="Hapus barang"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      closeable
    >
      <div>
        <span>
          Obat <strong>{{ product.nama_obat }}</strong> akan dihapus. Proses ?
        </span>
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogHapus = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteBarang"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import SupplierService from '@/services/SupplierService'
import GolonganObatService from '@/services/GolonganObatService'
import ObatService from '@/services/ObatService'
import PurchaseOrderService from '@/services/PurchaseOrderService'
import errorHandler from '@/helpers/error-handler'
import GridPoDetail from '@/components/pembelian/GridPoDetail'
import FormObat from '@/components/pembelian/FormObat'
import Hotkey from '@/components/Hotkey'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormObat,
    GridPoDetail,
    Hotkey,
  },
  data() {
    return {
      submitted: false,
      list_golongan_obat: null,
      supplierService: null,
      purchaseOrderService: null,
      acSupplier: [],
      dialogAdd: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSaveHeader: false,
      isLoadingSaveBarang: false,
      productIndex: -1,
      product: null,
      list_satuan: {},
      deleted: [],
      form: {
        id: 0,
        po_at: new Date(),
        po_no: null,
        supplier: null,
        golongan: null,
        status: 'draft',
        obat: [],
        status_bonus: false,
      },
      old_status: 'draft',
      list_status: null,
    }
  },
  created() {
    this.supplierService = new SupplierService()
    this.purchaseOrderService = new PurchaseOrderService()
  },
  computed: {
    golongan() {
      let result = null
      const obat = this.list_golongan_obat.find(
        (e) => e.id === this.form.golongan
      )
      if (obat) {
        result = obat.nama
      }
      return result
    },
  },
  mounted() {
    const self = this
    Promise.all([this.getGolonganObat()]).then(function (result) {
      self.list_golongan_obat = result[0]
    })

    if (this.id > 0) {
      this.isLoading = true
      this.purchaseOrderService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.po_at = this.form.po_at ? new Date(this.form.po_at) : null
            this.form.golongan = res.data.data.golongan.id
            this.old_status = this.form.status
            //this.form.status_bonus = this.form.status_bonus === 1
            this.getListStatus()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Purchase Order', this)
        })
        .finally(() => (this.isLoading = false))
    }
    this.getListStatus()
  },
  methods: {
    getListStatus() {
      let status = []
      switch (this.old_status) {
        case 'draft':
          status = ['draft', 'sent']
          break
        case 'sent':
          status = ['sent']
          break
        case 'rcvd':
          status = ['rcvd']
          break
        default:
          status.push('draft')
          break
      }
      this.list_status = status
    },
    async getGolonganObat() {
      const golonganObatService = new GolonganObatService()
      return await golonganObatService
        .get('?sort=nama')
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data golongan obat', this)
        })
    },
    searchSupplier(e) {
      this.supplierService
        .get('?filter[nama]=' + e.query)
        .then((res) => {
          this.acSupplier = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Supplier', this)
        })
    },
    add() {
      this.productIndex = -1

      if (!this.form.golongan) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Purchase Order',
          detail: 'Golongan obat harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      this.product = {
        id: 0,
        nama_obat: '',
        obat_id: 0,
        satuan_id: 0,
        satuan: '',
        quantity: 1,
        keterangan: 'SATU',
      }
      this.dialogAdd = true
    },
    async onEditBarang(item) {
      this.productIndex = this.form.obat.findIndex(
        (el) => el.id === item.id && el.obat_id === item.obat_id
      )
      const obatService = new ObatService()

      const obat = await obatService.get(`/${item.obat_id}`).then((res) => {
        if (res.data.status === 200) {
          return {
            id: item.obat_id,
            nama: item.nama_obat,
            kode: res.data.data.kode,
            satuan: res.data.data.satuan_tkcl,
            status_bonus: res.data.data.status_bonus,
          }
        }
      })
      this.list_satuan = obat.satuan
      item.obat = obat
      this.product = Object.assign({}, item)
      this.product.satuan_id = obat.satuan.id
      this.product.satuan = obat.satuan.nama

      this.dialogAdd = true
    },
    onConfirmDeletion(item) {
      this.product = Object.assign({}, item)
      this.dialogHapus = true
    },
    onDeleteBarang() {
      if (this.product.id !== 0) {
        this.deleted.push(this.product.id)
      }
      const index = this.form.obat.findIndex(
        (el) => el.id === this.product.id && el.obat_id === this.product.obat_id
      )
      this.form.obat.splice(index, 1)
      this.productIndex = -1
      this.product = {}
      this.dialogHapus = false
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const form = Object.assign({}, this.form)

      form.po_at = this.form.po_at
        ? dayjs(this.form.po_at).format('YYYY-MM-DD HH:mm:ss')
        : null

      form.golongan_id = this.form.golongan
      form.supplier_id = this.form.supplier.id

      form.status_bonus = this.form.status_bonus // ? 1 : 0

      delete form.golongan
      delete form.supplier

      console.log('form', form)

      if (this.form.id === 0) {
        this.isLoadingSaveHeader = true
        this.purchaseOrderService
          .add(form)
          .then((res) => {
            if (res.data.status === 200 && res.data.message === 'OK') {
              this.$toast.add({
                severity: 'success',
                summary: 'Purchase Order',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.$router.push({ name: 'PO' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Purchase order', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSaveHeader = false
            this.refreshDetail()
          })
      } else {
        this.isLoadingSaveHeader = true
        form.deleted = this.deleted
        this.purchaseOrderService
          .update(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Purchase Order',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Purchase Order', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSaveHeader = false
            this.refreshDetail()
          })
      }
    },
    refreshDetail() {
      if (this.form.id > 0) {
        this.purchaseOrderService
          .get(`/${this.id}`)
          .then((res) => {
            if (res.data.status === 200) {
              this.form.obat = res.data.data.obat
            }
          })
          .catch((err) => {
            errorHandler(err, 'Purchase Order (Obat)', this)
          })
      }
    },
    onSaveBarang(item) {
      this.product = Object.assign({}, item)
      if (this.productIndex >= 0) {
        Object.assign(this.form.obat[this.productIndex], this.product)
        this.dialogAdd = false
      } else {
        this.form.obat.push(this.product)
        this.$toast.add({
          severity: 'success',
          summary: 'Purchase Order',
          detail: 'Data obat berhasil ditambahkan.',
          life: 3000,
        })
      }
      this.isLoadingSaveBarang = false
    },
    onCloseFormBarang() {
      this.dialogAdd = false
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
  },
  validations() {
    return {
      form: {
        golongan: {
          required: helpers.withMessage('Golongan harus diisi.', required),
        },
        supplier: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },
      },
    }
  },
}
</script>
