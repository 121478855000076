<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSaveHeader"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
          />
          <Button
            :disabled="id > 0"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Pabrik"
            @click="addPabrik"
          />

          <Button
            icon="pi pi-file-excel"
            class="p-button-outlined mr-2"
            label="Download"
            @click="onDownloadTemplate"
          />
          <FileUpload
            mode="basic"
            accept=".xls"
            class="p-button-outlined mr-2"
            chooseLabel="Upload"
            :maxFileSize="10000000"
            auto
            custom-upload
            @uploader="onUpload"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'StokOpname' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label>Tanggal</label>
                <Calendar
                  v-model="form.son_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>No</label>
                <InputText
                  v-model="form.son_no"
                  class="w-full"
                  disabled
                  autofocus
                />
              </div>
              <div class="col-12 md:col-8">
                <DataTable
                  :value="list_pabrik"
                  responsiveLayout="scroll"
                  class="p-datatable-sm"
                >
                  <Column field="kode" header="Pabrik" />
                  <Column field="total_sku" header="Jumlah SKU" />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
        <grid-stok-opname-detail
          :items="form.obat"
          :loading="isLoading"
          @edit="onEditBarang"
          @delete="onConfirmDeletion"
        />
      </div>
    </form>
    <Dialog
      header="Edit barang"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :closable="false"
      modal
    >
      <form-obat
        :loading="isLoadingSaveBarang"
        :item="product"
        @close="onCloseFormBarang"
        @save="onSaveBarang"
      />
    </Dialog>
    <Dialog
      header="Tambah Pabrik"
      v-model:visible="dialogAddPabrik"
      :style="{ width: '30vw' }"
      modal
    >
      <form-add-stock-opname
        :loading="isLoadingSave"
        :item="itemPabrik"
        @save="onAddPabrik"
      />
    </Dialog>
    <Dialog
      header="Hapus barang"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div>
        <span>
          Obat <strong>{{ product.nama_obat }}</strong> akan dihapus. Proses ?
        </span>
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogHapus = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteBarang"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import FileUpload from 'primevue/fileupload'
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import StockOpnameService from '@/services/StockOpnameService'
import errorHandler from '@/helpers/error-handler'
import FormAddStockOpname from '@/components/gudang/FormAddStockOpname'
import GridStokOpnameDetail from '@/components/gudang/GridStokOpnameDetail'
import FormObat from '@/components/gudang/FormObat'
import Hotkey from '@/components/Hotkey'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormAddStockOpname,
    FileUpload,
    FormObat,
    GridStokOpnameDetail,
    Hotkey,
  },
  data() {
    return {
      itemPabrik: null,
      submitted: false,
      pabrikService: null,
      stockOpnameService: null,
      dialogEdit: false,
      dialogAddPabrik: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingSaveHeader: false,
      isLoadingSaveBarang: false,
      productIndex: -1,
      product: null,
      list_satuan: {},
      deleted: [],
      form: {
        id: 0,
        son_at: new Date(),
        son_no: null,
        pabrik: null,
        obat: [],
      },
      ls_batch: null,
      list_pabrik: [],
    }
  },
  created() {
    this.stockOpnameService = new StockOpnameService()
  },
  mounted() {
    if (this.id > 0) {
      this.isLoading = true
      this.loadGridData()
    }
  },
  methods: {
    loadGridData() {
      this.stockOpnameService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.son_at = this.form.son_at
              ? new Date(this.form.son_at)
              : null
            for (const [, value] of Object.entries(res.data.data.pabrik)) {
              this.list_pabrik.push({
                kode: value.nama,
                total_sku: value.total_sku,
              })
            }
          }
        })
        .catch((err) => {
          errorHandler(err, 'Stok Opname', this)
        })
        .finally(() => (this.isLoading = false))
    },
    addPabrik() {
      this.itemPabrik = {
        son_no: this.form.son_no,
        son_at: this.form.son_at,
        pabrik: [],
      }
      this.dialogAddPabrik = true
    },
    onAddPabrik(e) {
      this.isLoadingSave = true

      let pabrik = []
      let form = {}
      this.isLoadingSave = true
      e.pabrik.forEach((el) => {
        pabrik.push(el.id)
      })

      form.id = this.id
      form.pabrik = pabrik
      form.son_at = new Date(e.son_at)
      form.son_no = e.son_no

      this.stockOpnameService
        .addPabrik(form)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Stock Opname',
              detail: 'Data pabrik berhasil ditambahkan',
              life: 3000,
            })
            this.dialogAddPabrik = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data pabrik', this)
        })
        .finally(() => {
          this.isLoadingSave = false
          this.list_pabrik = []
          this.loadGridData()
        })
    },
    onUpload(e) {
      const id = this.id
      this.stockOpnameService
        .upload(id, e.files[0])
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Upload file',
              detail: 'Data stock opname berhasil diupload.',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Upload data stock opname', this)
        })
    },
    onDownloadTemplate() {
      const id = this.id
      this.stockOpnameService.download(id).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'stock-opname-list.xls')
        document.body.appendChild(link)
        link.click()
      })
    },
    async onEditBarang(item) {
      this.product = Object.assign({}, item)
      this.productIndex = this.form.obat.findIndex(
        (el) => el.id === item.id && el.obat_id === item.obat_id
      )
      this.dialogEdit = true
    },
    onConfirmDeletion(item) {
      this.product = Object.assign({}, item)
      this.dialogHapus = true
    },
    onDeleteBarang() {
      if (this.product.id !== 0) {
        this.deleted.push(this.product.id)
      }
      const index = this.form.obat.findIndex(
        (el) => el.id === this.product.id && el.obat_id === this.product.obat_id
      )
      this.form.obat.splice(index, 1)
      this.productIndex = -1
      this.product = {}
      this.dialogHapus = false
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.isLoadingSaveHeader = true

      let form = {}
      let obat = []
      form.id = this.id
      form.son_no = this.form.son_no
      form.son_at = this.form.son_at
        ? dayjs(this.form.son_at).format('YYYY-MM-DD HH:mm:ss')
        : null
      form.deleted = this.deleted
      this.form.obat.forEach((el) => {
        const stock_opname = !el.stock_opname ? 0 : el.stock_opname
        obat.push({
          id: el.id,
          stock_opname: stock_opname,
        })
      })

      form.obat = obat
      form.deleted = this.deleted

      this.stockOpnameService
        .update(form)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Stok Opname',
              detail: 'Data berhasil disimpan.',
              life: 3000,
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Stok Opname', this)
        })
        .finally(() => {
          this.isLoadingSaveHeader = false
          this.deleted = []
          this.loadGridData()
        })
    },
    onSaveBarang(item) {
      this.product = Object.assign({}, item)
      if (this.productIndex >= 0) {
        Object.assign(this.form.obat[this.productIndex], this.product)
        this.dialogEdit = false
      } else {
        this.form.obat.push(this.product)
        this.$toast.add({
          severity: 'success',
          summary: 'Stok Opname',
          detail: 'Data obat berhasil ditambahkan.',
          life: 3000,
        })
      }
      this.isLoadingSaveBarang = false
    },
    onCloseFormBarang() {
      this.dialogEdit = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.addPabrik()
      }
    },
  },
}
</script>
