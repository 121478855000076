<template>
  <div class="card">
    <DataTable
      v-model:expandedRows="expandedRows"
      :value="items"
      :rowClass="flaggedRowClass"
      class="p-datatable-sm"
      showGridlines
      scrollable
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column field="po_no" header="PO" style="min-width: 10rem" />
      <Column field="po_at" header="Tgl PO" style="min-width: 7rem">
        <template #body="{ data }">
          {{ formatDate(data.po_at) }}
        </template>
      </Column>
      <Column field="golongan" header="Golongan" style="min-width: 7rem" />
      <Column field="nama_obat" header="Obat" style="min-width: 13rem" />
      <Column
        field="quantity"
        header="QTY"
        style="min-width: 6.1rem; flex-grow: 1; justify-content: flex-end"
      />
      <Column field="satuan" header="Satuan" style="min-width: 7rem" />
      <Column
        field="harga"
        header="Harga"
        style="min-width: 7rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.harga, 2, 2) }}
        </template>
      </Column>
      <Column
        field="diskon"
        header="Diskon (%)"
        style="min-width: 7rem; flex-grow: 1; justify-content: flex-end"
      />
      <Column
        field="diskon_harga"
        header="Diskon (Rp)"
        style="min-width: 7rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.diskon_harga, 2, 2) }}
        </template>
      </Column>
      <!--
      <Column
        field="harga_diskon"
        header="Harga Diskon"
        style="min-width: 7rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.harga_diskon) }}
        </template>
      </Column>
      -->
      <Column
        field="harga_total"
        header="Jumlah"
        style="min-width: 9rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dpp) }}
        </template>
      </Column>
      <Column v-if="showButton" style="flex-grow: 1; justify-content: flex-end">
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            @click="$emit('edit', data)"
          />
          <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <grid-batch :items="slotProps.data.batch" />
      </template>
      <template #footer>
        <table width="340px" class="flex justify-content-end">
          <tr>
            <td width="100%">
              <table class="justify-content-end">
                <tr>
                  <td>SUB TOTAL</td>
                  <td style="width: 150px" class="text-right">
                    {{ formatCurrency(subTotal.subTotal) }}
                  </td>
                </tr>
                <tr>
                  <td>DISKON</td>
                  <td class="text-right">
                    {{ formatCurrency(subTotal.totalDiskon) }}
                  </td>
                </tr>
                <tr>
                  <td>DISKON TAMBAHAN</td>
                  <td class="text-right">
                    <InputNumber
                      v-model="diskon_tambahan"
                      class="ml-4"
                      :disabled="!showButton"
                      :minFractionDigits="0"
                      locale="id-ID"
                      @input="updateDiskon"
                    />
                  </td>
                </tr>
                <tr>
                  <td>DPP</td>
                  <td class="text-right">
                    {{ formatCurrency(totalDPP) }}
                  </td>
                </tr>
                <tr>
                  <td>PPN</td>
                  <td class="text-right">
                    {{ formatCurrency(subTotal.totalPPN) }}
                  </td>
                </tr>
                <tr>
                  <td>PPH 22&nbsp;</td>
                  <td class="text-right">
                    <div class="flex justify-content-between">
                      <div>
                        <InputNumber
                          v-model="pph"
                          :min="0"
                          placeholder="PPH"
                          showButtons
                          class="w-3rem"
                          input-class="w-3rem"
                          :disabled="!showButton"
                          @input="updatePPH"
                        />
                      </div>
                      <div>
                        <InputNumber
                          v-model="totalPPH"
                          :minFractionDigits="0"
                          mode="currency"
                          currency="IDR"
                          locale="id-ID"
                          class="w-8rem"
                          input-class="w-8rem"
                          disabled
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>TOTAL PEMBELIAN</td>
                  <td class="text-right">
                    {{ formatCurrency(total) }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </template>
    </DataTable>
  </div>
</template>

<script>
import GridBatch from '@/components/batch/GridBatch'
import { formatCurrency, formatDate } from '@/helpers'
// import SettingService from '@/services/SettingService'

export default {
  components: {
    GridBatch,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    diskon: {
      type: Number,
      default: 0,
    },
    jenisHarga: {
      type: Number,
      default: 2,
    },
    nilaiPph: {
      type: Number,
      default: 2,
    },
    ppn: {
      type: Number,
      default: 0,
    },
    /*
      list_jenis_harga: [
        { value: 1, label: 'Termasuk Pajak' },
        { value: 2, label: 'Belum Termasuk Pajak' },
        { value: 3, label: 'Non Pajak' },
      ],
      Termasuk pajak = PPN kosong
      Belum termasuk pajak = PPN isi
      non pajak = PPN isi
    */
  },
  data() {
    return {
      expandedRows: [],
      diskon_tambahan: this.diskon,
      totalDiskon: 0,
      totalDPP: 0,
      totalPPN: 0,
      total: 0,
      totalPPH: 0,
      pph: this.nilaiPph,
    }
  },
  mounted() {
    /*
    const settingService = new SettingService()
    settingService.get('?filter[key]=PPN').then((res) => {
      this.ppn = res.data.data.value
      /!*
      this.totalDPP =
        this.subTotal.subTotal -
        this.subTotal.totalDiskon -
        this.diskon_tambahan
      *!/
    })
    */
    this.totalDPP = this.subTotal.totalDPP
    //this.totalPPN = this.jenisHarga === 1 ? 0 : this.subTotal.totalPPN
    this.totalPPN = this.subTotal.totalPPN
    this.totalPPH =
      this.pph > 0 ? Math.floor(this.totalDPP * (this.pph / 100)) : 0
    this.total = this.totalDPP + this.totalPPN + this.totalPPH
  },
  watch: {
    diskon(e) {
      this.diskon_tambahan = e
    },
    nilaiPph(e) {
      this.pph = e
      /*
      this.totalDPP =
        this.subTotal.totalDPP -
        this.subTotal.totalDiskon -
        this.diskon_tambahan
      */
      this.totalDPP = this.subTotal.totalDPP
      //this.totalPPN = this.jenisHarga === 1 ? 0 : this.subTotal.totalPPN
      this.totalPPN = this.subTotal.totalPPN
      this.totalPPH = e > 0 ? Math.floor(this.totalDPP * (e / 100)) : 0
      this.total = this.totalDPP + this.totalPPN + this.totalPPH // - this.diskon
    },
    subTotal() {
      /*
      this.totalDPP = val.subTotal - val.totalDiskon - this.diskon_tambahan
      */
      this.totalDPP = this.subTotal.totalDPP
      //this.totalPPN = this.jenisHarga === 1 ? 0 : this.subTotal.totalPPN
      this.totalPPN = this.subTotal.totalPPN
      this.totalPPH =
        this.pph > 0 ? Math.floor(this.totalDPP * (this.pph / 100)) : 0
      this.total = this.totalDPP + this.totalPPN + this.totalPPH // - this.diskon
    },
    jenisHarga() {
      /*
      this.totalDPP = this.subTotal.subTotal - this.subTotal.totalDiskon
      */
      this.totalDPP = this.subTotal.totalDPP
      //this.totalPPN = this.jenisHarga === 1 ? 0 : this.subTotal.totalPPN
      this.totalPPN = this.subTotal.totalPPN
      this.totalPPH =
        this.pph > 0 ? Math.floor(this.totalDPP * (this.pph / 100)) : 0
      this.total = this.totalDPP + this.totalPPN + this.totalPPH // - this.diskon
    },
  },
  computed: {
    subTotal() {
      let res = {}
      const self = this

      const subTotalNett = this.items.reduce(function (total, item) {
        const sub_jumlah = Math.round(item.harga * item.quantity)
        return total + sub_jumlah
      }, 0)

      const totalDPPWoDT = this.items.reduce(function (total, item) {
        const dpp = Math.round(
          Math.round(
            (item.harga_diskon === 0 ? item.harga : item.harga_diskon) *
              item.quantity
          )
        )
        item.dpp = dpp
        return total + dpp
      }, 0)

      let totalDPP = totalDPPWoDT

      if (this.diskon_tambahan) {
        totalDPP = this.items.reduce(function (total, item) {
          const dppOld = item.dpp
          const dtPercent = dppOld / totalDPPWoDT
          const dppPlus = dppOld - dtPercent * self.diskon_tambahan
          item.dpp = dppPlus
          return total + dppPlus
        }, 0)
      }

      //const totalDPPWithDiskonTambahan

      /*
      let totalPPN = this.items.reduce(function (total, item) {
        const dpp =
          (item.harga_diskon === 0 ? item.harga : item.harga_diskon) *
          item.quantity
        const nilai_ppn = dpp * (self.ppn / 100)
        return total + nilai_ppn
      }, 0)
      */

      const totalDiskon = this.items.reduce(function (total, item) {
        const diskon_rp =
          item.diskon_harga > 0
            ? (Math.round(item.diskon_harga * 100) / 100) * item.quantity
            : 0
        return total + diskon_rp
      }, 0)

      const totalPPN = Math.floor(totalDPP * (self.ppn / 100))
      /*
      let totalPPN = this.items.reduce(function (total, item) {
        return total + Math.floor(item.dpp * (self.ppn / 100))
      }, 0)

      if (this.diskon_tambahan) {
        totalPPN = this.items.reduce(function (total, item) {
          const dtPercent = item.dpp / totalDPPWoDT
          const dppPlus = item.dpp - dtPercent * self.diskon_tambahan
          return total + Math.floor(dppPlus * (self.ppn / 100))
        }, 0)
      }
      */
      /*
      const totalPPN =
        this.items.reduce(function (total, item) {
          const dpp = Math.round(
            (item.harga_diskon === 0 ? item.harga : item.harga_diskon) *
              item.quantity
          )
          return total + Math.floor(dpp * (self.ppn / 100))
        }, 0) - Math.floor(this.diskon_tambahan * (self.ppn / 100))
      */

      // console.log('totalPPN B', totalPPN)
      res.subTotal = subTotalNett
      res.totalDPP = totalDPP
      res.totalDiskon = totalDiskon
      res.totalPPN = totalPPN
      return res
    },
  },
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
    updateDiskon(e) {
      this.totalDPP = this.subTotal.totalDPP
      this.totalPPN = this.jenisHarga === 1 ? 0 : this.subTotal.totalPPN
      this.totalPPH =
        this.pph > 0 ? Math.floor(this.totalDPP * (this.pph / 100)) : 0
      this.total = this.totalDPP + this.totalPPN + this.totalPPH - e.value
      this.$emit('diskon', e.value)
    },
    updatePPH(e) {
      this.$emit('pph', e.value)
    },
    qtyCheck(data) {
      return (
        data.batch.reduce((tot, el) => tot + el.quantity, 0) !== data.quantity
      )
    },
    flaggedRowClass(data) {
      return this.qtyCheck(data) ? 'flagged-rows' : ''
    },
  },
}
</script>

<style scoped>
div >>> .flagged-rows {
  background-color: #ffcfcf !important;
}
</style>
