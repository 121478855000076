<template>
  <div>
    <DataTable
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="true"
      :rows="10"
      :scrollable="true"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <!-- <Column field="kode_obat" header="Kode" style="min-width: 10rem" /> -->
      <Column field="nama_obat" header="Obat" style="min-width: 10rem" />
      <Column field="quantity" header="QTY" style="width: 3rem" />
      <Column field="satuan" header="Satuan" style="width: 3rem" />
      <Column field="keterangan" header="Keterangan" style="width: 3rem" />
      <Column>
        <template #body="{ data }">
          <div class="flex justify-content-end align-items-end">
            <Button
              :disabled="disableButton"
              type="button"
              icon="pi pi-pencil"
              class="p-button-outlined p-button-secondary p-button-text mr-2"
              @click="$emit('edit', data)"
            />
            <Button
              :disabled="disableButton"
              type="button"
              icon="pi pi-trash"
              class="p-button-outlined p-button-danger p-button-text"
              @click="$emit('delete', data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
