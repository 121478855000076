<template>
  <div>
    <DataTable
      :value="items"
      :rowClass="rowClass"
      class="p-datatable-sm"
      scrollable
      showGridlines
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column field="pi_no" header="Faktur" style="min-width: 9rem" />
      <Column field="pi_at" header="Tanggal terima" style="min-width: 7rem">
        <template #body="{ data }">
          {{ formatDate(data.pi_at) }}
        </template>
      </Column>
      <Column field="inv_at" header="Tanggal Faktur" style="min-width: 10rem">
        <template #body="{ data }">
          {{ formatDate(data.inv_at) }}
        </template>
      </Column>
      <Column field="inv_no" header="No Invoice" style="min-width: 10rem" />
      <Column
        field="jumlah"
        header="Jumlah"
        style="min-width: 150px; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column>
      <Column style="flex-grow: 1; justify-content: flex-end">
        <template #body="{ data }">
          <div class="flex justify-content-end align-items-end">
            <!-- <Button
              type="button"
              icon="pi pi-pencil"
              class="p-button-outlined p-button-secondary p-button-text mr-2"
              @click="$emit('edit', data)"
            /> -->
            <Button
              :disabled="showButton"
              icon="pi pi-trash"
              class="p-button-outlined p-button-danger p-button-text"
              @click="$emit('delete', data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
    rowClass(data) {
      return data.telat ? 'bg-orange-100' : null
    },
  },
}
</script>
