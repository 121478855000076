<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="po_no"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      row-hover
      paginator
      scrollable
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column field="po_no" header="No" style="min-width: 12rem" sortable>
        <template #body="{ data }">
          {{ data.po_no }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        filter-field="order_between"
        field="po_at"
        header="Tanggal"
        style="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.po_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="supplier.nama"
        sort-field="supplier"
        filter-field="supplier"
        header="Supplier"
        style="min-width: 20rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="golongan.nama"
        sort-field="golongan"
        filter-field="golongan"
        header="Golongan"
        style="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="golonganObat"
            :showClear="true"
            optionValue="nama"
            optionLabel="nama"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column field="status" header="Status" style="min-width: 100px" sortable>
        <template #body="{ data }">
          <div :class="'status-badge status-' + data.status">
            {{ data.status }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="['draft', 'sent', 'rcvd']"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column style="flex-grow: 1; min-width: 100px; justify-content: flex-end">
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-print"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            v-tooltip.top="'Cetak Purchase Order'"
            @click="$emit('print', data)"
          />
          <riwayat-status :items="data.status_transition" />
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
          <!-- <Button
            :disabled="data.status !== 'draft'"
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          /> -->
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="card">
          <DataTable
            :value="slotProps.data.obat"
            :scrollable="true"
            class="p-datatable-sm"
            showGridlines
          >
            <!-- <Column field="kode_obat" header="Kode" style="min-width: 5rem" /> -->
            <Column field="nama_obat" header="Obat" style="min-width: 20rem" />
            <Column field="quantity" header="QTY" style="min-width: 3rem" />
            <Column field="satuan" header="Satuan" style="min-width: 5rem" />
            <Column
              field="keterangan"
              header="Keterangan"
              style="min-width: 20rem"
            />
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatNumber, formatDate } from '@/helpers'
import CalendarFilter from '@/components/CalendarFilter'
import RiwayatStatus from '@/components/RiwayatStatus'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    golonganObat: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    CalendarFilter,
    RiwayatStatus,
  },
  data() {
    return {
      expandedRows: [],
      options: this.gridOptions,
      filters: {
        po_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        order_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
        golongan: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatNumber(value) {
      return formatNumber(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
