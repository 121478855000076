<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSaveHeader"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
          />
          <Button
            :loading="isLoadingInvoice"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Faktur"
            @click="add"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'Kontrabon' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-3">
                <label
                  :class="{
                    'p-error': v$.form.kb_at.$invalid && submitted,
                  }"
                >
                  Tanggal</label
                >
                <Calendar
                  v-model="form.kb_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  :class="{
                    'p-invalid': v$.form.kb_at.$invalid && submitted,
                  }"
                />
                <small
                  v-if="
                    (v$.form.kb_at.$invalid && submitted) ||
                    v$.form.kb_at.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.kb_at.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-3">
                <label>No Kontra</label>
                <InputText v-model="form.kb_no" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-6">
                <label
                  :class="{
                    'p-error': v$.form.supplier.$invalid && submitted,
                  }"
                >
                  Supplier
                </label>
                <AutoComplete
                  v-model="form.supplier"
                  :suggestions="acSupplier"
                  :min-length="2"
                  :class="{
                    'p-invalid': v$.form.supplier.$invalid && submitted,
                  }"
                  class="w-full"
                  input-class="w-full"
                  field="nama"
                  @complete="searchSupplier($event)"
                  @item-select="selectSupplier"
                  :disabled="form.detail.length > 0"
                >
                  <template #item="slotProps">
                    <div>
                      {{ slotProps.item.kode }} - {{ slotProps.item.nama }}
                    </div>
                  </template>
                </AutoComplete>
                <small
                  v-if="
                    (v$.form.supplier.$invalid && submitted) ||
                    v$.form.supplier.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.supplier.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-3">
                <label>Tanggal kembali</label>
                <Calendar
                  v-model="form.kembali_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  showButtonBar
                />
              </div>
              <div class="field col-12 md:col-9">
                <label
                  :class="{
                    'p-error':
                      form.jenis_kontrabon === 'telat' &&
                      v$.form.nama_salesman.$invalid &&
                      submitted,
                  }"
                  >Nama Salesman</label
                >
                <InputText
                  v-model="form.nama_salesman"
                  :class="{
                    'p-invalid':
                      form.jenis_kontrabon === 'telat' &&
                      v$.form.nama_salesman.$invalid &&
                      submitted,
                  }"
                  class="w-full"
                />
                <small
                  v-if="
                    (form.jenis_kontrabon === 'telat' &&
                      v$.form.nama_salesman.$invalid &&
                      submitted) ||
                    v$.form.nama_salesman.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.nama_salesman.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-8">
                <label>Alamat</label>
                <InputText v-model="form.alamat" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label>Kota</label>
                <InputText v-model="form.kota" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-6">
                <label
                  :class="{ 'p-error': v$.form.nama.$invalid && submitted }"
                  >Nama</label
                >
                <InputText
                  v-model="form.nama"
                  :class="{
                    'p-invalid': v$.form.nama.$invalid && submitted,
                  }"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.nama.$invalid && submitted) ||
                    v$.form.nama.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.nama.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-6">
                <label
                  :class="{
                    'p-error':
                      form.jenis_kontrabon === 'telat' &&
                      v$.form.jabatan.$invalid &&
                      submitted,
                  }"
                  >Jabatan</label
                >
                <InputText
                  v-model="form.jabatan"
                  :class="{
                    'p-invalid':
                      form.jenis_kontrabon === 'telat' &&
                      v$.form.jabatan.$invalid &&
                      submitted,
                  }"
                  class="w-full"
                />
                <small
                  v-if="
                    (form.jenis_kontrabon === 'telat' &&
                      v$.form.jabatan.$invalid &&
                      submitted) ||
                    v$.form.jabatan.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.jabatan.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-12 mt-2">
                <!--
              <div
                v-if="form.jenis_kontrabon === 'telat'"
                class="field col-12 mt-2"
              >
                -->
                <div class="grid mr-0 md:mr-1">
                  <div class="field col-11 mr-0 pr-0">
                    <label>Alasan Kontrabon</label>
                    <AutoComplete
                      v-model="alasan"
                      :suggestions="optionAlasan"
                      :min-length="2"
                      class="w-full"
                      input-class="w-full"
                      field="alasan"
                      :dropdown="true"
                      @complete="searchAlasan($event)"
                    >
                      <template #item="slotProps">
                        {{ slotProps.item.alasan }}
                      </template>
                    </AutoComplete>
                  </div>
                  <div class="field col-1 ml-0 pl-0">
                    <label style="width: 100%">&nbsp;</label>
                    <Button
                      icon="pi pi-plus"
                      class="p-button-outlined"
                      @click="dialogAlasan = true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isNaN(id) || id === 0" class="flex">
          <div
            class="bg-orange-100 mr-2 mb-3"
            style="width: 20px; height: 20px"
          ></div>
          = KONTRABON TERLAMBAT
        </div>
        <grid-kontrabon-detail
          :items="form.detail"
          :loading="isLoading"
          @delete="onConfirmDeletion"
        />
      </div>
    </form>
    <Dialog
      header="Tambah invoice"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      maximizable
    >
      <form-kontrabon-invoice
        :invoice="list_invoice"
        :exclude-list="list_invoice_id"
        @close="onCloseFormInvoice"
        @save="onSaveInvoice"
      />
    </Dialog>
    <!-- <Dialog
      header="Edit invoice"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <form-Invoice :item="formEdit" @save="onUpdateInvoice" />
    </Dialog> -->
    <Dialog
      header="Hapus invoice dari kontra bon ?"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <div>
        <span
          >Faktur <strong>{{ invoice.pi_no }}</strong> akan dihapus dari kontra
          bon. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogHapus = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteInvoice"
        />
      </template>
    </Dialog>
    <Dialog
      header="Tambah Alasan"
      v-model:visible="dialogAlasan"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :closable="false"
      modal
    >
      <div>
        <div class="formgrid grid">
          <div class="field col-12">
            <label>Alasan</label>
            <InputText v-model="alasan.alasan" class="w-full" />
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogAlasan = false"
          class="p-button-text p-button-secondary mr-2"
        />
        <Button
          label="Tambah"
          icon="pi pi-check"
          class="p-button-text"
          @click="addAlasan"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import GridKontrabonDetail from '@/components/pembelian/GridKontrabonDetail'
import FormKontrabonInvoice from '@/components/pembelian/FormKontrabonInvoice'
// import FormInvoice from '@/components/pembelian/FormInvoice'
import PurchaseInvoiceService from '@/services/PurchaseInvoiceService'
// import SettingService from '@/services/SettingService'
import SupplierService from '@/services/SupplierService'
import KontraService from '@/services/KontraPembelianService'
import { helpers, required } from '@vuelidate/validators'
import Hotkey from '@/components/Hotkey'
import errorHandler from '@/helpers/error-handler'
import { useAuthStore } from '@/store/auth'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    GridKontrabonDetail,
    FormKontrabonInvoice,
    // FormInvoice,
    Hotkey,
  },
  data() {
    return {
      kontraService: null,
      supplierService: null,
      submitted: false,
      isLoading: false,
      isLoadingSaveHeader: false,
      isLoadingInvoice: false,
      form: {
        id: 0,
        kb_at: new Date(),
        kb_no: null,
        supplier: null,
        nama_salesman: null,
        nama: null,
        jabatan: null,
        jenis_kontrabon: 'tidak-telat',
        detail: [],
      },
      acSupplier: [],
      list_invoice: [],
      deleted: [],
      dialogAdd: false,
      dialogAlasan: false,
      alasan: {
        id: 0,
        alasan: '',
      },
      // dialogEdit: false,
      dialogHapus: false,
      // formEdit: {},
      invoice: null,
      optionAlasan: [],
      supplierTop: 0,
    }
  },
  created() {
    this.supplierService = new SupplierService()
    this.kontraService = new KontraService()
    /*
    const settingService = new SettingService()
    settingService.get('?filter[key]=alasan_kontrabon').then((res) => {
      this.form.alasan_kontrabon = res.data.data.value
      this.optionAlasan = res.data.data.initial_value
    })
    */
  },
  computed: {
    list_invoice_id() {
      return this.form.detail.map((x) => x.id)
    },
  },
  mounted() {
    if (this.id > 0) {
      this.isLoading = true
      this.kontraService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.kembali_at = this.form.kembali_at
              ? new Date(this.form.kembali_at)
              : null
            this.form.kb_at = this.form.kb_at ? new Date(this.form.kb_at) : null
            this.form.supplier = res.data.data.supplier
            this.form.alamat = res.data.data.supplier.alamat
            this.form.kota = res.data.data.supplier.kota
            this.form.kembali_at = this.form.kembali_at
              ? new Date(this.form.kembali_at)
              : null
            this.alasan = { id: 1, alasan: res.data.data.alasan_kontrabon }

            if (this.form.detail) {
              if (this.form.detail.length > 0) {
                this.supplierTop = this.form.supplier.top
                this.onComputeReturnDate()
              }
            }
          }
        })
        .catch((err) => {
          errorHandler(err, 'Kontra bon', this)
        })
        .finally(() => (this.isLoading = false))
    } else {
      const auth = useAuthStore()
      this.form.nama = auth.user.name
    }
  },
  methods: {
    add() {
      if (!this.form.supplier) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Kontra bon',
          detail: 'Supplier harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }
      // load invoice by supplier
      const purchaseInvoiceService = new PurchaseInvoiceService()
      const self = this
      self.isLoadingInvoice = true
      purchaseInvoiceService
        .get(
          `?filter[supplier_id]=${this.form.supplier.id}&filter[status]=done&filter[has_bon]=0`
        )
        .then((res) => {
          if (!res.data.data || res.data.data.length === 0) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Kontra bon',
              detail: `Faktur pembelian untuk supplier ${this.form.supplier.nama} tidak ditemukan!`,
              life: 3000,
            })
            self.isLoadingInvoice = false
            return
          } else {
            self.list_invoice = res.data.data
            self.dialogAdd = true
            self.isLoadingInvoice = false
          }
        })
        .catch(() => {
          self.isLoadingInvoice = false
        })
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      let form = {}
      let detail = []
      let isInvoiceValid = true

      if (this.form.detail.length === 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Faktur',
          detail: 'Faktur harus diisi !',
          life: 3000,
        })
        return
      }

      this.form.detail.forEach((el) => {
        if (!el.inv_no) {
          isInvoiceValid = false
        }

        let data = {}
        if (this.form.id === 0) {
          data.pi_id = el.id
        } else if (this.form.id > 0 && el.pi_id) {
          data.id = el.id
        } else if (this.form.id > 0 && !el.pi_id) {
          data.id = 0
          data.pi_id = el.id
        }

        detail.push(data)
      })

      if (!isInvoiceValid) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Faktur',
          detail: 'No invoice harus diisi !',
          life: 3000,
        })
        return
      }

      form.id = this.form.id ? this.form.id : 0
      if (this.form.kb_no) {
        form.kb_no = this.form.kb_no
      }
      if (this.form.kembali_at) {
        form.kembali_at = this.form.kembali_at
          ? dayjs(this.form.kembali_at).format('YYYY-MM-DD HH:mm:ss')
          : null
      }
      form.kb_at = this.form.kb_at
        ? dayjs(this.form.kb_at).format('YYYY-MM-DD HH:mm:ss')
        : null
      form.supplier_id = this.form.supplier.id
      form.detail = detail
      form.nama = this.form.nama
      form.jabatan = this.form.jabatan
      form.nama_salesman = this.form.nama_salesman
      form.jenis_kontrabon = this.form.jenis_kontrabon
      if (this.alasan) {
        if (this.alasan.alasan) {
          form.alasan_kontrabon = this.alasan.alasan
        }
      }

      if (form.id === 0) {
        this.isLoadingSaveHeader = true
        this.kontraService
          .add(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Kontra bon',
                detail: 'Data berhasil disimpan',
                life: 3000,
              })
              this.$router.push({ name: 'Kontrabon' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Kontra bon', this)
          })
          .finally(() => (this.isLoadingSaveHeader = false))
      } else {
        this.isLoadingSaveHeader = true
        form.deleted = this.deleted
        this.kontraService
          .update(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Kontra bon',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Kontra bon', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSaveHeader = false
          })
      }
    },
    selectSupplier(e) {
      this.form.alamat = e.value.alamat
      this.form.kota = e.value.kota
      this.supplierTop = e.value.top
      this.onComputeReturnDate()
    },
    /*
    getTanggalKembaliOld(tgl_terima) {
      const top = this.supplierTop
      const quotient = Math.floor(top / 30)
      const remainder = top % 30
      const tmp_result = dayjs(
        tgl_terima.add(quotient, 'month').add(remainder, 'day')
      )

      let result = tmp_result

      const tgl_terima_month = new Date(tgl_terima.format(`YYYY/MM/DD`)).getMonth()
      const tmp_result_month = new Date(tmp_result.format(`YYYY/MM/DD`)).getMonth()

      if (tgl_terima_month <= 1 && tmp_result_month >= 2) {
        result = dayjs(tmp_result).add(3, 'day')
      }

      return new Date(result.format(`YYYY/MM/DD`))
    },
    */
    getTanggalKembali(tgl_terima) {
      const top = this.supplierTop
      let addDay = top
      for (let d = 1; d <= top; d++){
        const tmp_result = new Date(dayjs(tgl_terima.add(d, 'day')).format(`YYYY/MM/DD`))
        const date = tmp_result.getDate()
        const year = tmp_result.getFullYear()
        const month = tmp_result.getMonth()
        const days = new Date(year, month + 1, 0).getDate()
        let add = (days === 31 && date === days) ? 1 : 0
        addDay += add
      }
      let result = dayjs(tgl_terima.add(addDay, 'day'))
      return new Date(result.format(`YYYY/MM/DD`))
    },
    searchSupplier(e) {
      this.supplierService
        .get('?filter[nama]=' + e.query)
        .then((res) => {
          this.acSupplier = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Supplier', this)
        })
    },
    // async onEditInvoice(item) {
    //   this.formEdit = Object.assign({}, item)
    //   this.formEdit.pi_at = this.formEdit.pi_at
    //     ? new Date(this.formEdit.pi_at)
    //     : null
    //   this.formEdit.inv_at = this.formEdit.inv_at
    //     ? new Date(this.formEdit.inv_at)
    //     : null
    //   this.dialogEdit = true
    // },
    onConfirmDeletion(item) {
      this.invoice = Object.assign({}, item)
      this.dialogHapus = true
    },
    onDeleteInvoice() {
      if (this.invoice.id && this.invoice.id > 0) {
        this.deleted.push(this.invoice.id)
      }
      const index = this.form.detail.findIndex(
        (el) => el.id === this.invoice.id && el.pi_no === this.invoice.pi_no
      )
      this.form.detail.splice(index, 1)
      if (this.form.detail.length === 0) {
        this.form.jenis_kontrabon = 'tidak-telat'
      }
      this.invoice = {}
      this.dialogHapus = false
    },
    onSaveInvoice(item) {
      let firstDate = null
      if (this.form.detail.length > 0) {
        let el = this.form.detail[0]
        firstDate = new Date(el.pi_at)
      }

      for (let i = 0; i < item.length; i++) {
        let el = item[i]
        if (i === 0) {
          if (!firstDate) {
            firstDate = new Date(el.pi_at)
            el.kembali_at = this.getTanggalKembali(dayjs(el.pi_at))
          }
        }
        this.form.detail.push(el)
        // if (!firstDate) {
        //   this.form.detail.push(el)
        // } else {
        //   if ((new Date(el.pi_at)).valueOf() === firstDate.valueOf()) {
        //     this.form.detail.push(el)
        //   }
        // }
      }
      this.onComputeReturnDate()
      this.dialogAdd = false
    },
    onComputeReturnDate() {
      const detail = this.form.detail
      if (detail.length > 0) {
        let isLate = false
        detail.forEach((el) => {
          el.kembali_at = this.getTanggalKembali(dayjs(new Date(el.pi_at)))
        })
        const kontraDate = new Date(this.form.kb_at)
        const returnDate = new Date(detail[0].kembali_at)
        const hari = dayjs(returnDate).diff(kontraDate, 'day')
        isLate = hari <= 14

        this.form.detail.forEach((el) => {
          el.telat = isLate
        })
        if (isLate) {
          this.form.jenis_kontrabon = 'telat'
        }
        this.form.kembali_at = detail[0].kembali_at
      }
    },
    /*
    onComputeReturnDate() {
      let isLate = false
      const detail = this.form.detail

      detail.forEach((el) => {
        el.kembali_at = this.getTanggalKembali(dayjs(new Date(el.pi_at)))
      })

      detail.sort((a, b) => a.kembali_at - b.kembali_at)
      const kontraDate = new Date(this.form.kb_at)
      const firstDate = dayjs(detail[0].kembali_at)
      const lastDate = dayjs(detail[detail.length - 1].kembali_at)
      const avgDate = ~~(lastDate.diff(firstDate, 'day') / 2)
      const returnDate = firstDate.add(avgDate, 'day')
      this.form.kembali_at = new Date(returnDate)
      const hari = dayjs(returnDate).diff(kontraDate, 'day')

      if (hari <= 14) {
        isLate = true
        if (hari > 0) {
          this.form.kembali_at = new Date(
            kontraDate.add(14, 'day').format('YYYY-MM-DD')
          )
        }
      }

      if (isLate) {
        this.form.jenis_kontrabon = 'telat'
      }
    },
    */
    onCloseFormInvoice() {
      this.dialogAdd = false
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
    searchAlasan(e) {
      this.kontraService
        .getAlasan('?filter[alasan]=' + e.query)
        .then((res) => {
          this.optionAlasan = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Alasan', this)
        })
    },
    addAlasan() {
      this.kontraService
        .addAlasan({ alasan: this.alasan.alasan })
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Tambah Alasan',
              detail: 'Data berhasil disimpan',
              life: 3000,
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Tambah Alasan', this)
        })
        .finally(() => {
          this.dialogAlasan = false
        })
    },
  },
  validations() {
    return {
      form: {
        kb_at: {
          required: helpers.withMessage('Tanggal harus diisi.', required),
        },
        supplier: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },
        nama_salesman: {
          required: helpers.withMessage('Nama salesman diisi.', required),
        },
        nama: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        jabatan: {
          required: helpers.withMessage('Jabatan harus diisi.', required),
        },
      },
    }
  },
}
</script>
