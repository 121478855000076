<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12">
          <label>Pabrik</label>
          <InputText v-model="form.pabrik.nama" class="w-full" disabled />
        </div>
        <div class="field col-12 md:col-2">
          <label>Kode</label>
          <InputText v-model="form.kode_obat" class="w-full" disabled />
        </div>
        <div class="field col-12 md:col-6">
          <label>Obat</label>
          <InputText v-model="form.nama_obat" class="w-full" disabled />
        </div>
        <div class="field col-12 md:col-4">
          <label>Satuan</label>
          <InputText v-model="form.satuan" class="w-full" disabled />
        </div>
        <div class="field col-12 md:col-3">
          <label>Stock Akhir</label>
          <InputText v-model="form.stock_akhir" class="w-full" disabled />
        </div>
        <div class="field col-12 md:col-3">
          <label
            :class="{
              'p-error': v$.form.stock_opname.$invalid && submitted,
            }"
            >Stok Opname</label
          >
          <InputNumber
            v-model="form.stock_opname"
            input-class="w-full"
            :class="{
              'p-invalid': v$.form.stock_opname.$invalid && submitted,
            }"
            autofocus
            @input="updateQty"
            @keyup.enter="handleSubmit(!v$.$invalid)"
          />
          <small
            v-if="
              (v$.form.stock_opname.$invalid && submitted) ||
              v$.form.stock_opname.$pending.$response
            "
            class="p-error"
            >{{ v$.form.stock_opname.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-3">
          <label>Selisih</label>
          <InputText v-model="form.selisih" class="w-full" disabled />
        </div>
        <div class="field col-12 md:col-3">
          <label>Harga</label>
          <InputText v-model="form.harga" class="w-full" disabled />
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
      form: this.item,
    }
  },
  methods: {
    updateQty(so) {
      this.form.selisih = parseInt(so.value) - this.form.stock_akhir
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const form = Object.assign({}, this.form)

      this.$emit('save', form)
    },
  },
  validations() {
    return {
      form: {
        stock_opname: {
          required: helpers.withMessage('Jumlah harus diisi.', required),
        },
      },
    }
  },
}
</script>
