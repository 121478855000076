<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div class="formgrid grid">
        <div class="field col-12">
          <label>Obat</label>
          <InputText
            v-model="form.nama_obat"
            type="text"
            class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Quantity</label>
          <InputNumber
            v-model="form.quantity"
            :minFractionDigits="0"
            type="text"
            class="w-full"
            input-class="w-full"
            autofocus
            disabled
            @input="updateQty"
            @keyup.enter="handleSubmit()"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Satuan</label>
          <InputText
            v-model="form.satuan"
            type="text"
            class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-5">
          <label>Harga</label>
          <div class="w-full">
            <InputNumber
              v-model="form.harga"
              :minFractionDigits="2"
              mode="currency"
              currency="IDR"
              locale="id-ID"
              style="width: calc(100% - 38px) !important"
              :disabled="disableHarga"
              @input="updateHarga"
              @keyup.enter="handleSubmit()"
            />
            <Button
              type="button"
              icon="pi pi-history"
              title="History"
              class="p-button-outlined p-button-secondary p-button-text"
              :disabled="!form.history_harga || form.history_harga.length === 0"
              @click="toggleHistory"
            />
          </div>

          <OverlayPanel
            ref="op"
            appendTo="body"
            id="overlay_panel"
            style="width: 450px"
            :breakpoints="{ '960px': '75vw' }"
          >
            <DataTable :value="form.history_harga">
              <Column field="harga" header="Harga">
                <template #body="slotProps">
                  {{ formatCurrency(slotProps.data.harga) }}
                </template>
              </Column>
              <Column field="harga_at" header="Tanggal Pembelian">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.harga_at) }}
                </template>
              </Column>
            </DataTable>
          </OverlayPanel>
        </div>
        <div class="field col-12 md:col-3">
          <label>Diskon (%)</label>
          <InputNumber
            v-model="form.diskon"
            :minFractionDigits="2"
            :min="0"
            :max="100"
            locale="id-ID"
            type="text"
            class="w-full"
            input-class="w-full"
            @input="updateDiskon"
            @keyup.enter="handleSubmit()"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Diskon (Rp)</label>
          <InputNumber
            v-model="form.diskon_harga"
            :minFractionDigits="0"
            :disabled="true"
            class="w-full"
            input-class="w-full"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Harga Diskon</label>
          <InputNumber
            v-model="form.harga_diskon"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Jumlah</label>
          <InputNumber
            v-model="form.harga_total"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      disableHarga: false,
      disableDiskon: false,
      disableDiskonHarga: false,
    }
  },
  mounted() {
    console.log('status_bonus', this.form.status_bonus)
    if (this.form.diskon > 0) {
      this.disableDiskonHarga = true
    } else if (this.form.diskon_harga > 0) {
      this.disableDiskon = true
    }
    if (this.form.status_bonus) {
      this.disableDiskonHarga = true
      this.disableDiskon = true
      this.disableHarga = true
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('save', this.form)
    },
    updateQty(e) {
      this.form.quantity = e.value
      this.hitungHarga(
        this.form.harga,
        this.form.diskon,
        this.form.diskon_harga,
        e.value
      )
    },
    updateHarga(e) {
      this.hitungHarga(
        e.value,
        this.form.diskon,
        this.form.diskon_harga,
        this.form.quantity
      )
    },
    updateDiskon(e) {
      this.hitungHarga(
        this.form.harga,
        e.value,
        this.form.diskon_harga,
        this.form.quantity
      )
      this.disableDiskonHarga = e.value > 0
    },
    hitungHarga(harga, diskon, diskon_harga, quantity) {
      if (diskon > 0) {
        diskon = diskon > 100 ? 100 : diskon
        this.form.harga_diskon = harga - harga * (diskon / 100)
        this.form.diskon_harga = harga * (diskon / 100)
      } else {
        this.form.diskon_harga = 0
        this.form.harga_diskon = harga
      }

      this.form.harga_total = Math.round(quantity * this.form.harga_diskon)
    },
    toggleHistory(e) {
      this.$refs.op.toggle(e)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
