<template>
  <div>
    <DataTable
      v-model:expandedRows="expandedRows"
      v-model:filters="filters"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      filterDisplay="row"
      class="p-datatable-sm"
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      row-hover
      paginator
      scrollable
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column
        field="kb_no"
        header="No kontra"
        style="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        filter-field="order_between"
        field="kb_at"
        header="Tanggal"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.kb_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        filter-field="order_between"
        field="kembali_at"
        header="Tanggal kembali"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.kembali_at) }}
        </template>
      </Column>
      <Column
        field="supplier.nama"
        sort-field="supplier"
        filter-field="supplier"
        header="Supplier"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="jumlah"
        header="Total"
        style="min-width: 90px; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column>
      <Column style="flex-grow: 1; justify-content: flex-end">
        <template #body="{ data }">
          <Button
            v-if="data.jenis_kontrabon === 'telat'"
            type="button"
            icon="pi pi-print"
            class="p-button-outlined p-button-primary p-button-text mr-2"
            v-tooltip.top="'Surat telat kontra bon'"
            @click="$emit('printtelat', data)"
          />
          <Button
            type="button"
            icon="pi pi-print"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            v-tooltip.top="'Surat terima kontra bon'"
            @click="$emit('print', data)"
          />
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
          <!-- <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          /> -->
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="card">
          <DataTable
            v-model:expandedRows="expandedRows"
            :value="slotProps.data.detail"
            class="p-datatable-sm"
            showGridlines
            scrollable
          >
            <Column
              field="pi_no"
              header="Sales invoice"
              style="min-width: 9rem"
            />
            <Column field="pi_at" header="Tgl. terima" style="min-width: 7rem">
              <template #body="{ data }">
                {{ formatDate(data.pi_at) }}
              </template>
            </Column>
            <Column field="inv_at" header="Tgl. faktur" style="min-width: 7rem">
              <template #body="{ data }">
                {{ formatDate(data.inv_at) }}
              </template>
            </Column>
            <Column
              field="inv_no"
              header="No Invoice"
              style="min-width: 15rem"
            />
            <Column
              field="jumlah"
              header="Jumlah"
              style="min-width: 150px; flex-grow: 1; justify-content: flex-end"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.jumlah) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'
import CalendarFilter from '@/components/CalendarFilter'

export default {
  components: {
    CalendarFilter,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      expandedRows: [],
      options: this.gridOptions,
      filters: {
        kb_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        order_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
