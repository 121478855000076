<template>
  <div>
    <DataTable
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="true"
      :rows="10"
      :scrollable="true"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column field="pabrik.nama" header="Pabrik" style="min-width: 9rem" />
      <Column field="kode_obat" header="Kode" style="min-width: 5rem" />
      <Column field="nama_obat" header="Obat" style="min-width: 20rem" />
      <Column field="satuan" header="Satuan" />
      <Column
        field="harga"
        header="Harga"
        dataType="numeric"
        style="
          min-width: 6rem;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.harga) }}
        </template>
      </Column>
      <Column
        field="stock_akhir"
        header="Stok Akhir"
        style="
          min-width: 3rem;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatNumber(data.stock_akhir) }}
        </template>
      </Column>
      <Column
        field="stock_opname"
        header="Stok Opname"
        style="
          min-width: 3rem;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          <div
            class="w-full p-1 text-right"
            :class="data.stock_opname == null ? 'bg-orange-100' : ''"
          >
            {{ formatNumber(data.stock_opname) }}
          </div>
        </template>
      </Column>
      <Column
        field="selisih"
        header="Selisih"
        style="
          min-width: 3rem;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatNumber(data.selisih) }}
        </template>
      </Column>
      <Column
        field="total"
        header="Total"
        style="
          min-width: 3.5rem;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatNumber(data.total) }}
        </template>
      </Column>
      <Column>
        <template #body="{ data }">
          <div class="flex justify-content-end align-items-end">
            <Button
              :disabled="showButton"
              type="button"
              icon="pi pi-pencil"
              class="p-button-outlined p-button-secondary p-button-text mr-2"
              @click="$emit('edit', data)"
            />
            <Button
              :disabled="showButton"
              type="button"
              icon="pi pi-trash"
              class="p-button-outlined p-button-danger p-button-text"
              @click="$emit('delete', data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { formatNumber, formatDate, formatCurrency } from '@/helpers'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatNumber(value) {
      return formatNumber(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
    formatCurrency(value) {
      return formatCurrency(value)
    },
  },
}
</script>
