<template>
  <div>
    <div class="formgrid grid">
      <div class="field col-12">
        <DataTable
          v-model:selection="selected"
          v-model:filters="filters"
          :value="list_items"
          :globalFilterFields="[
            'item_no',
            'item_at',
            'kb_no',
            'pi_no',
            'pr_no',
            'jumlah',
          ]"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="formgrid grid">
              <div class="field col-12 md:col-3">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filters['global'].value"
                    placeholder="Filter invoice"
                  />
                </span>
              </div>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3rem" />
          <Column field="kb_no" header="No Kontra" style="min-width: 10rem" />
          <Column field="pi_no" header="No Invoice" style="min-width: 10rem" />
          <Column field="pr_no" header="No Retur" style="min-width: 10rem" />
          <Column field="item_at" header="Tgl Bukti" style="min-width: 8rem">
            <template #body="{ data }">
              <div v-if="data.kb_no">{{ formatDate(data.kb_at) }}</div>
              <div v-else-if="!data.kb_no && data.pi_no">
                {{ formatDate(data.pi_at) }}
              </div>
              <div v-else-if="data.pr_no">{{ formatDate(data.pr_at) }}</div>
              <div v-else>-</div>
            </template>
          </Column>
          <Column
            field="jumlah"
            header="Total"
            style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
          >
            <template #body="{ data }">
              {{ formatCurrency(data.jumlah) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  props: {
    kontra: {
      type: Array,
      default() {
        return []
      },
    },
    retur: {
      type: Array,
      default() {
        return []
      },
    },
    invoice: {
      type: Array,
      default() {
        return []
      },
    },
    kontraExcludeList: {
      type: Array,
      default() {
        return []
      },
    },
    returExcludeList: {
      type: Array,
      default() {
        return []
      },
    },
    invoiceExcludeList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selected: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kb_no: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        kb_at: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        jumlah: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      disabledSave: true,
    }
  },
  watch: {
    selected(val) {
      this.disabledSave = !(val && val.length > 0)
    },
  },
  computed: {
    list_items() {
      const result_kontra = this.kontra.slice()
      const result_invoice = this.invoice.slice()
      const result_retur = this.retur.slice()

      const filtered_kontra = result_kontra.filter((item) => {
        return this.kontraExcludeList.indexOf(item.kb_no) === -1
      })
      const filtered_invoice = result_invoice.filter((item) => {
        return this.invoiceExcludeList.indexOf(item.pi_no) === -1
      })
      const filtered_retur = result_retur.filter((item) => {
        return this.returExcludeList.indexOf(item.pr_no) === -1
      })

      return filtered_kontra.concat(filtered_invoice).concat(filtered_retur)
    },
  },
  methods: {
    add() {
      if (this.selected !== null) {
        if (this.selected.length > 0) {
          const result = this.selected.slice()
          let output = []
          let item = {}
          result.forEach((el) => {
            // kontrabon
            if (el.kb_no) {
              item = {}
              item.id = 0
              item.kbd_id = el.id
              item.item_at = el.kb_at
              item.kb_no = el.kb_no
              item.pi_no = el.pi_no
              item.pr_no = null
              item.jumlah = el.jumlah
              item.diskon = 0
              item.jumlah_dibayar = el.jumlah
              item.jumlah_saldo = el.jumlah_saldo
              item.type = el.type
              item.kembali_at = el.kembali_at
              output.push(item)
            }
            // invoice
            if (!el.kb_no && el.pi_no) {
              item = {}
              item.id = 0
              item.pi_id = el.id
              item.item_at = el.pi_at
              item.kb_no = null
              item.pi_no = el.pi_no
              item.pi_at = el.pi_at
              item.pr_no = null
              item.jumlah = el.jumlah
              item.diskon = 0
              item.jumlah_dibayar = el.jumlah
              item.jumlah_saldo = el.jumlah_saldo
              item.type = el.type
              output.push(item)
            }
            // retur
            if (el.pr_no) {
              item = {}
              item.id = 0
              item.prd_id = el.id
              item.item_at = el.pr_at
              item.kb_no = null
              item.pi_no = el.pi_no
              item.pi_at = el.pi_at
              item.pr_no = el.pr_no
              item.jumlah = el.jumlah
              item.diskon = 0
              item.jumlah_dibayar = el.jumlah
              item.jumlah_saldo = el.jumlah
              item.type = el.type
              item.obat = []

              el.obat.forEach((retur) => {
                let obat = {}
                obat.id = 0
                obat.prd_id = retur.id
                obat.item_at = el.pr_at
                obat.kb_no = null
                obat.pi_no = retur.pi_no
                obat.pi_at = retur.pi_at
                obat.pr_no = el.pr_no
                obat.jumlah = retur.harga_total
                obat.diskon = 0
                obat.jumlah_dibayar = retur.harga_total
                obat.jumlah_saldo = retur.harga_total
                obat.type = el.type
                item.obat.push(obat)
              })
              output.push(item)
            }
          })
          this.$emit('save', output)
        }
      }
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      value = value ? value : 0
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
